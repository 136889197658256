import AnimationInputRange from './../../../elements/form/inputRange/animate/';
import Utils from '../../../../scripts/vendors/utils';

/**
 * CalculatorCredit
 * @class
 * @constructor
 * @public
 */
class CalculatorCredit {
  /**
   * Constructor
   * @returns {class}
   */
  constructor( form, index ) {
    // Engage engines
    this._init( form, index );
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  _init( form, index ) {
    let _form = form,
      _index = index;

    const _INPUT_NAME_CREDIT_PROTECTION = 'requestedLoanHasPPI';
    const _OUTPUT_CLASS_PREFIX = 'calculator-form__output--';

    let _currency = _form.dataset['currency'],
      _inputRanges = _form.querySelectorAll('input[type="range"]'),
      _rangeAmount = _form.querySelector('.calculator-credit__input-amount--js'),
      _rangeRuntime = _form.querySelector('.calculator-credit__input-runtime--js'),
      _radioCreditProtectionYes = _form.querySelector('.calculator-credit__radio-protection-yes--js'),
      _radioGroupCreditProtection = _form.querySelectorAll('input[name="radioCreditProtection"]'),
      _inputFieldLoanPPI = _form.querySelector('input[name="'+ _INPUT_NAME_CREDIT_PROTECTION +'"]'),
      _labels = Array.prototype.slice.call(_form.querySelectorAll('label[for]')),
      _inputs = Array.prototype.slice.call(_form.querySelectorAll('input[name]:not([type="hidden"])'));

    // result-fields of calculation
    let _yourppi = {
      min: _form.querySelector('.yourppi__min > strong'),
      max: _form.querySelector('.yourppi__max > strong')
    };

    let _yourrate = {
        inputmin:   _form.querySelector('input[name="calculatedMinRate"]'),
        inputmax:   _form.querySelector('input[name="calculatedMaxRate"]'),
        min:        _form.querySelector('.yourrate__min'),
        max:        _form.querySelector('.yourrate__max')
        // maxtotal:   _form.querySelector('.yourrate__maxtotal'),
        // mintotal:   _form.querySelector('.yourrate__mintotal')
    };

    let apr1 = Utils.calculator.rountTo((12 * (Math.pow((1 + 5.9 / 100), 1 / 12) - 1)), 0.0001).toFixed(4);
    let apr2 = Utils.calculator.rountTo((12 * (Math.pow((1 + 10.9 / 100), 1 / 12) - 1)), 0.0001).toFixed(4);

    let scrollListener,
      animationDone = false;

    let calculateMonthlyInstallment = (apr, loan, term) => {
      let P = loan,
          r = apr / 12,
          n = parseInt(term, 10),
          ppiRate = 5.985 / 100,
          tempCalc = Math.pow((1 + r), n), // (1+r)^n
          monthlyInstallment = Utils.calculator.rountTo((P * (r * tempCalc) / (tempCalc - 1)), 0.05);

          if (_radioCreditProtectionYes.checked) {
              monthlyInstallment += Utils.calculator.rountTo(monthlyInstallment * ppiRate, 0.05);
          }

          return monthlyInstallment.toFixed(2);
    };

    let calculateMonthlyPPI = (apr, loan, term) => {
        let P = loan,
            r = apr / 12,
            n = parseInt(term, 10),
            ppiRate = 5.985 / 100,
            tempCalc = Math.pow((1 + r), n), // (1+r)^n
            monthlyInstallment = Utils.calculator.rountTo((P * (r * tempCalc) / (tempCalc - 1)), 0.05),
            monthlyPPI;

        if (_radioCreditProtectionYes.checked) {
            monthlyPPI = Utils.calculator.rountTo(monthlyInstallment * ppiRate, 0.05);
        }

        return monthlyPPI;
    };

    // handler, if range-slider was dragged.
    // -> writes range-value in suited output-field
    let onRangeValueChanged = (event) => {

      // find suited output-field
      let outputSelector = '.' + _OUTPUT_CLASS_PREFIX + Utils.string.camelCaseToDash(event.target.name);
      let output = _form.querySelector(outputSelector),
        outputSpan = output.querySelector('.output');
        // write value in output-field
        outputSpan.innerHTML = Utils.calculator.thousendSpace(event.target.value);

        let max = Utils.calculator.thousendSpace(calculateMonthlyInstallment(apr2, parseInt(_rangeAmount.value), parseInt(_rangeRuntime.value)));
        let min = Utils.calculator.thousendSpace(calculateMonthlyInstallment(apr1, parseInt(_rangeAmount.value), parseInt(_rangeRuntime.value)));

        _yourrate.max.innerHTML =  max;
        _yourrate.min.innerHTML =  min;
        _yourrate.inputmax.value = max;
        _yourrate.inputmin.value = min;

        if (_radioCreditProtectionYes.checked) {
          _yourppi.min.innerHTML = Utils.calculator.thousendSpace( calculateMonthlyPPI(apr1, parseInt(_rangeAmount.value), parseInt(_rangeRuntime.value)).toFixed(2) );
          _yourppi.max.innerHTML = Utils.calculator.thousendSpace( calculateMonthlyPPI(apr2, parseInt(_rangeAmount.value), parseInt(_rangeRuntime.value)).toFixed(2) );
        }
    };

    // handler, if radio-group was toggled
    let onCreditProtectionChanged = (event) => {

      let fieldWillBeSubmitted = _inputFieldLoanPPI.getAttribute('name') == _INPUT_NAME_CREDIT_PROTECTION;

      // mirror radio-selection onto hidden input (`_inputFieldLoanPPI`) used for submission of the radio-value
      if (fieldWillBeSubmitted && !_radioCreditProtectionYes.checked) {
        // change name so that form doesn't submit field
        _inputFieldLoanPPI.setAttribute('name','');
      }
      if (!fieldWillBeSubmitted && _radioCreditProtectionYes.checked) {
        // change name so that form will submit field
        _inputFieldLoanPPI.setAttribute('name',_INPUT_NAME_CREDIT_PROTECTION);
      }

      // re-calculate ppi-values and update output-fields
      let max = Utils.calculator.thousendSpace(calculateMonthlyInstallment(apr2, parseInt(_rangeAmount.value), parseInt(_rangeRuntime.value)));
      let min = Utils.calculator.thousendSpace(calculateMonthlyInstallment(apr1, parseInt(_rangeAmount.value), parseInt(_rangeRuntime.value)));

      _yourrate.max.innerHTML =  max;
      _yourrate.min.innerHTML =  min;
      _yourrate.inputmax.value = max;
      _yourrate.inputmin.value = min;
      // _yourrate.maxtotal.innerHTML =  Utils.calculator.thousendSpace((max * parseInt(_rangeRuntime.value)).toFixed(2) + ' ' + _currency);
      // _yourrate.mintotal.innerHTML = Utils.calculator.thousendSpace((min * parseInt(_rangeRuntime.value)).toFixed(2) + ' ' + _currency);

      if(!_radioCreditProtectionYes.checked) {
          _yourppi.min.innerHTML = '0.00 ';
          _yourppi.max.innerHTML = '0.00 ';
      } else {

          _yourppi.min.innerHTML = Utils.calculator.thousendSpace( calculateMonthlyPPI(apr1, parseInt(_rangeAmount.value), parseInt(_rangeRuntime.value)).toFixed(2) );
          _yourppi.max.innerHTML = Utils.calculator.thousendSpace( calculateMonthlyPPI(apr2, parseInt(_rangeAmount.value), parseInt(_rangeRuntime.value)).toFixed(2) );
      }
    };

    /**
     * add listener to input-range and radio-buttons to update
     * the calculation output.
     *
     * @param {NodeList} - list of input-ranges
     * @param {NodeList} - list of radio-buttons
     * @returns {void}
     */
    let addInputListeners = ( inputRanges, radioGroupCreditProtection ) => {
      let isIE11 = !!window.MSInputMethodContext && !!document.documentMode,
        event;
      // init range-slider -> add listener
      for (let i = 0; i < inputRanges.length; i++) {
        let inputRange = inputRanges[i];

        //ie11 needs different listener
        if (isIE11) {
          inputRange.addEventListener('change', onRangeValueChanged );
          // event = document.createEvent('Event');
          // event.initEvent('change', true, true);
        } else {
          inputRange.addEventListener('input', onRangeValueChanged );
          // event = new Event('input');
        }
        // inputRange.dispatchEvent(event)
      }

      // handle radio-button change event
      for (let i = 0; i < radioGroupCreditProtection.length; i++) {
        radioGroupCreditProtection[i].addEventListener('change', onCreditProtectionChanged );
      }
      // trigger change once to update the hidden-input mirroring the radio-selcection for form-submission
      Utils.events.dispatchEventIE11(radioGroupCreditProtection[0], 'change');
    };

    /**
     * input-id's must be generated dynamically, as the same calculator could be used multiple times on one page.
     * constructor-parameter `_index` (e.g. 0, 1, n) is used as suffix to have unique id's per calculator.
     *
     * id's are only necessary, if there is an associated label in the markup ( connection by 'for`-Attribute )
     *
     * ```
     *  f.e. <input name="foo" /> //would be processed if there is:
     *    <label for="foo">myLabel</label>
     *
     *  result: <input id="foo_0" name="foo" />
     *        <label for="foo_0">myLabel</label>
     *
     * ```
     * !! - special case for radio-buttons -> id's must be different for each radio-button
     *
     * @param {Array} all input-fields, that are not hidden
     * @param {Array} - all labels with a `for` attribute
     * @param {Number} index: given index via constructor
     * @returns {void}
     */
    let addInputIdsByName = ( inputs, labels, index ) => {

        // collect all candidates: remove suffix of radio-button-labels ( '_A_', '_B_') to correlate matching input
        let labelForValues = labels.map( label => label.getAttribute('for').replace('_A','').replace('_B','')),
          lastInput;

        inputs.map( input => {
          // console.log(_labels.map( label => label.getAttribute('for')), input.name);
          for (var i = 0; i < labelForValues.length; i++) {
            // input has associated label
            if (labelForValues[i] == input.name) {

              if ( lastInput && lastInput.name == input.name) {
                lastInput.id = input.name + '_A_' + index;
                input.id = input.name + '_B_' +  index;
                // console.log('lastInput id: ', lastInput.id);
              } else {
                input.id = input.name + '_' + index;
              }
              // console.log('input needs id: ', input.name, input.id);
              lastInput = input;
            }
          }
        });

        let lastLabel;
        labels.map( label => {
          label.setAttribute('for', label.getAttribute('for') + '_' + index );
        });
    };

    /**
     * Invokes Animation of Input-Range-Values initially ( once ).
     * Condition: DOM-Element (Range) must be fully visible in Viewport
     *
     * @param {Nodes} all input-ranges
     *
     * @returns {void}
     */
    let animateRanges = function(inputRanges) {

      let intersecting = false;

      for (var j = 0; j < inputRanges.length; j++) {

        if ( Utils.intersect.isElementInViewport(inputRanges[j], 30) ) {
          intersecting = true;

          break;
        }
      }

      if (!intersecting || animationDone) {
        return;
      }

      animationDone = true;
      window.removeEventListener('scroll', scrollListener);

      // start animation, if on range is in visible area
      for (var i = 0; i < inputRanges.length; i++) {
        let range = inputRanges[i],
            options = {};

          options.duration = 750;
          options.endValue = range.value;
          range.value = 0;

          new AnimationInputRange(range, options);
      }
    };
    // hook calculation to input listeners
    addInputListeners( _inputRanges, _radioGroupCreditProtection);

    // input-range values should be animated-in from 0 - to target-value
    scrollListener = Utils.events.debounce(function() {
      animateRanges(_inputRanges);
    }, 50, false);

    window.addEventListener('scroll', scrollListener);

    setTimeout(function(){
      animateRanges(_inputRanges);
    }, 250);

     // add id's dynamically to be able to use for-attributes ( required for radio-buttons )
    addInputIdsByName( _inputs, _labels, _index);

    return this;
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'CalculatorCredit';
  }
};

module.exports = CalculatorCredit;
